import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  GridActionsCellItemProps,
  GridRenderCellParams,
  GridColDef,
  GridRowParams,
  GridFilterItem,
} from '@mui/x-data-grid-premium';
import { GridActionsCellItemLink, FakeButtonLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { portfolioOrderStatusToInfo } from '../../constants/tables/portfolioOrder';
import { portfolioOrderExecutionStatusToInfo } from '../../constants/tables/portfolioOrderExecution';
import { usePermissions } from '../../hooks';
import {
  portfolioOrderByRequestIdSelector,
  portfolioOrderSelector,
} from '../../redux/modules/portfolioOrder/portfolioOrder.selectors';
import { fetchPortfolioOrders } from '../../redux/modules/portfolioOrder/portfolioOrder.actions';
import { ReducerState } from '../../redux/store.types';
import { locations } from '../../routes/locations';
import { PortfolioOrderExecutionStatus, PortfolioOrderStatus } from '../../types/portfolioOrder';
import { UserPortfolioRequestType } from '../../types/userPortfolioRequest';
import * as permissions from '../../utils/permissions';
import { FundCategory } from '../../types/indexFund';
import { productTypeSelector } from '../../redux/modules/productType/product-type.selectors';
import { useAppDispatch } from '../../redux/hooks';
import { fetchProductTypes } from '../../redux/modules/productType/product-type.actions';

interface PortfolioOrderTableProps {
  type: UserPortfolioRequestType;
  setProducts: (ps) => any;
  setFundCategories: (fcs) => any;
  setStatuses: (ss) => any;
  setExecutionStatuses: (ss) => any;
  externalFilter?: any[];
}

export function PortfolioOrderTable({
  type,
  setProducts,
  setFundCategories,
  setStatuses,
  setExecutionStatuses,
  externalFilter = [],
}: PortfolioOrderTableProps) {
  const requestId = `portfolio-order-list-${type}`;
  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const tableRef = useRef<TableRef>(null);
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 100,
    },
    {
      field: 'orderId',
      headerName: 'Order Id',
      type: 'string',
      width: 350,
    },
    {
      field: 'userPortfolioRequest.id',
      headerName: 'Request Id',
      valueGetter: (params) => params.row?.userPortfolioRequest?.id,
      groupingValueGetter: (params) => params.row?.userPortfolioRequest?.id,
      width: 100,
    },
    {
      field: 'userPortfolioRequest.type.name',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: Object.values(UserPortfolioRequestType),
      minWidth: 150,
      valueGetter: (params) => params.row?.userPortfolioRequest?.type?.name,
    },
    {
      field: 'userPortfolioRequest.fundCategory.name',
      headerName: 'Fund Category',
      type: 'singleSelect',
      valueOptions: Object.values(FundCategory),
      width: 130,
      valueGetter: (params) => params.row?.userPortfolioRequest?.fundCategory?.name,
      groupingValueGetter: (params) => params.row?.userPortfolioRequest?.fundCategory?.name,
    },
    {
      field: 'userPortfolioRequest.userPortfolio.fundAmount',
      headerName: 'Amount',
      type: 'number',
      valueGetter: (params) => {
        if (params.row?.userPortfolioRequest?.type?.name === UserPortfolioRequestType.REDEMPTION) {
          return params.row?.userPortfolioRequest?.changes?.redeemAll
            ? 'all'
            : params.row?.userPortfolioRequest?.changes?.amount;
        }

        return params.row?.userPortfolioRequest?.userPortfolio?.fundAmount;
      },
      groupingValueGetter: (params) => params.row?.userPortfolioRequest?.userPortfolio?.fundAmount,
      width: 100,
    },
    {
      field: 'userPortfolioRequest.userPortfolio.subscribedPortfolio.productType.nameEn',
      headerName: 'Portfolio',
      type: 'string',
      width: 300,
      valueGetter: (params) => {
        if (params.row?.id) {
          return `(${params.row?.userPortfolioRequest?.userPortfolio?.subscribedPortfolio?.id}) ${params.row?.userPortfolioRequest?.userPortfolio?.subscribedPortfolio?.productType?.nameEn}`;
        }
        return '';
      },
      groupingValueGetter: (params) => params.row?.userPortfolioRequest?.userPortfolio?.subscribedPortfolio?.nameEn,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <Tooltip title={params.value} followCursor>
            <FakeButtonLink
              startIcon={<OpenInNewIcon />}
              href={locations.subscribedPortfolio(
                params.row?.userPortfolioRequest?.userPortfolio?.subscribedPortfolio?.id,
              )}
            >
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(PortfolioOrderStatus),
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = portfolioOrderStatusToInfo[params.row?.status?.name as PortfolioOrderStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'portfolioOrderExecutions[0].status.name',
      headerName: 'Execution Status',
      type: 'singleSelect',
      valueOptions: Object.values(PortfolioOrderExecutionStatus),
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row.portfolioOrderExecutions?.[0]?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = portfolioOrderExecutionStatusToInfo[params.value as PortfolioOrderExecutionStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.value;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.value} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const canDoActions = usePermissions({ items: [permissions.PortfolioOrder.execute] });
  const { productTypes } = useSelector(productTypeSelector);

  const { updateStatusStatus } = useSelector(portfolioOrderSelector);
  const { totalPortfolioOrders, portfolioOrders, fetchStatus } = useSelector((state: ReducerState) =>
    portfolioOrderByRequestIdSelector(state, requestId),
  );
  const loading = fetchStatus === fetchStatuses.pending;
  // TODO: Find a way to store this
  // const [initialFilterModel, setInitialFilterModel] = useSearchParamsState('filter', '');
  // const [initialSortModel, setInitialSortModel] = useSearchParamsState(
  //   'sort',
  //   JSON.stringify([{ field: 'id', sort: 'desc' }]),
  // );
  // const [initialPage, setInitialPage] = useSearchParamsState('page', '0');
  // const [initialPageSize, setInitialPageSize] = useSearchParamsState('pageSize', '100');

  useEffect(() => {
    dispatch(fetchProductTypes({ limit: 100, offset: 0 }));
  }, []);

  useEffect(() => {
    const products = productTypes.map((product) => product.nameEn);

    const fundCategories = Object.values(FundCategory);

    const statues = Object.values(PortfolioOrderStatus);

    const executionStatuses = Object.values(PortfolioOrderExecutionStatus);

    setProducts([...products, 'Select All', 'Clear All']);

    setFundCategories([...fundCategories, 'Select All', 'Clear All']);

    setStatuses([...statues, 'Select All', 'Clear All']);

    setExecutionStatuses([...executionStatuses, 'Select All', 'Clear All']);
  }, [portfolioOrders]);

  useEffect(() => {
    if (updateStatusStatus === fetchStatuses.success) {
      tableRef?.current?.reload();
      enqueueSnackbar('Portfolio Order status updated!', { variant: 'success' });
    }
    if (updateStatusStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Portfolio Order update status error!', { variant: 'error' });
    }
  }, [updateStatusStatus]);

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (`${params.id}`.split('/').length === 2) {
          const orderId = `${params.id}`.split('/').pop();
          actions.push(
            <GridActionsCellItemLink
              key={`${params.id}-portfolio-order-list-actions`}
              icon={<OpenInNewIcon />}
              label="Link"
              href={locations.portfolioOrder(orderId)}
            />,
          );
        }

        return actions;
      },
    };

    if (canDoActions) {
      setColumns([actionColumn, ...columns]);
    }
  }, [canDoActions]);

  return (
    <Table
      ref={tableRef}
      fetchItems={fetchPortfolioOrders}
      rows={portfolioOrders}
      columns={columns}
      loading={loading}
      rowCount={totalPortfolioOrders}
      disableRowGrouping={false}
      rowGroupingColumnMode="single"
      initialRowGroupingModel={['orderId']}
      defaultGroupingExpansionDepth={2}
      initialColumnVisibilityModel={{ orderId: false, 'userPortfolioRequest.type.name': false }}
      externalFilter={
        (externalFilter.length
          ? [{ field: 'type.name', value: type, operator: 'is', id: 1 }, ...externalFilter]
          : [{ field: 'type.name', value: type, operator: 'is', id: 1 }]) as GridFilterItem[]
      }
      // initialFilterModel={parseJSON(initialFilterModel)}
      // initialSortModel={parseJSON(initialSortModel)}
      // initialPage={+initialPage}
      // initialPageSize={+initialPageSize}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      sortingMode="client"
      fetchItemsCustomParams={{ requestId }}
      requiredFilters={[{ field: 'userPortfolioRequest.type.name', value: type, operator: 'is', id: 1 }]}
      // onFilterModelChange={(filterModel) => setInitialFilterModel(JSON.stringify(filterModel))}
      // onSortModelChange={(sortModel) => setInitialSortModel(JSON.stringify(sortModel))}
      // onPageChange={(page) => setInitialPage(`${page}`)}
      // onPageSizeChange={(pageSize) => setInitialPageSize(`${pageSize}`)}
    />
  );
}
