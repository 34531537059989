import { apiSlice } from '../../../api/common/apiSlice';
import { toQueryParams } from '../../../utils/request';
import { FetchLedgerResponse } from '../../../types/ledger';

export const ledgerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchLedger: builder.query<FetchLedgerResponse, { userId: number; data?: any }>({
      query: ({ userId, data }) => ({
        url: `/admin/ledger/accounts/users/${userId}${data ? `?${toQueryParams(data)}` : ''}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useFetchLedgerQuery } = ledgerApiSlice;
