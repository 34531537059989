import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { CircularProgress, Typography } from '@mui/material';
import { LedgerTable } from './LedgerTable';

interface LedgerProps {
  accountId: number;
  fetchLedger: (params: { userId: number; data?: any }) => any;
  type: string;
  memo?: string;
  hideFilter?: boolean;
}

export function Ledger({ type, fetchLedger, accountId, memo = '', hideFilter }: LedgerProps) {
  const [ledgerItems, setLedgerItems] = useState<any>({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filter, setFilter] = useState('');

  const payload = useMemo(
    () => ({
      type,
      page,
      perPage,
      ...(filter ? { filter } : {}),
      ...(memo ? { memo } : {}),
    }),
    [type, page, perPage, filter],
  );

  const {
    data: ledgerAccounts,
    isLoading,
    isError,
  } = fetchLedger({
    userId: accountId,
    data: payload,
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setLoading(true);
  }, [filter]);

  useEffect(() => {
    setError(isError);
  }, [isError]);

  // Data handling effect
  useEffect(() => {
    setLedgerItems(ledgerAccounts);
  }, [ledgerAccounts]);

  useEffect(() => {
    if (!ledgerItems || (ledgerItems && !Object.keys(ledgerItems).length)) return;
    setLoading(false);
  }, [ledgerItems]);

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Typography variant="h6">Something went wrong</Typography>
      </Grid>
    );
  }

  return (
    <Grid container>
      {/* Check the old implementation od ledger if this code needed */}
      {/* {selectedAccounts?.data && (
        <Grid item>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            value={selectedAccounts}
            onChange={(event: any, newValue: string[]) => {
              setSelectedAccounts(newValue);
            }}
            options={ledgerAccounts || []}
            sx={{ width: 300 }}
            multiple
            renderInput={(params) => <TextField {...params} label="Account" />}
          />
        </Grid>
      )} */}

      <LedgerTable
        items={ledgerItems}
        perPage={perPage}
        setPage={setPage}
        setPerPage={setPerPage}
        setFilter={setFilter}
        filter={filter}
        hideFilter={hideFilter}
      />
    </Grid>
  );
}
