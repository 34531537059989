import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useAppDispatch } from '../../redux/hooks';
import { fetchFeeAccounts } from '../../redux/modules/feeAccount/feeAccount.actions';
import { feeAccountSelector } from '../../redux/modules/feeAccount/feeAccount.selectors';
import { FeeAccountsDetails } from './FeeAccountsDetails';
import { Ledger } from '../../components/RefactoredLedger';
import { useFetchLedgerQuery } from '../../redux/features/ledger/refactoredLedger.apiSlice';

export function FeeTab() {
  const dispatch = useAppDispatch();
  const { error } = useSelector(feeAccountSelector);

  useEffect(() => {
    dispatch(fetchFeeAccounts());
  }, []);

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 0 }} spacing={2}>
        <Typography variant="h6">Accounts</Typography>
        <FeeAccountsDetails />
      </Grid>
      {/* <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}> */}
      {/*   <SatelliteAccountsTable /> */}
      {/* </Grid> */}
      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
        <Typography variant="h6">Cash Ledger</Typography>
        <Ledger accountId={-1} fetchLedger={useFetchLedgerQuery} type="cash" memo="Fee Collection" hideFilter />
      </Grid>
    </>
  );
}
