import { useCallback, useEffect, useState } from 'react';
import uuid4 from 'uuid4';
import { debounce } from 'lodash';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import moment from 'moment';
import { round2 } from '../../utils/number';
import { GridCellExpand } from '../Table';

type LedgerTableProps = {
  items: any;
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  setFilter: (val: string) => void;
  perPage: number;
  filter: string;
  hideFilter?: boolean;
};

export function LedgerTable({ items, setPage, setPerPage, perPage, setFilter, filter, hideFilter }: LedgerTableProps) {
  const [ledgerItems, setLedgerItems] = useState<any>({});
  const [value, setValue] = useState('');

  useEffect(() => {
    setLedgerItems(items);
  }, [items]);

  const debouncedSearch = useCallback(
    debounce((filterValue) => {
      setFilter(filterValue);
      setPage(1);
    }, 500),
    [],
  );

  const onFilterChanged: TextFieldProps['onChange'] = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filterValue = event.target.value;
    setValue(filterValue);
    debouncedSearch(filterValue);
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const renderLedgerItems = () => {
    let isGray = true;
    let prevMeta: string | null = null;
    if (!ledgerItems?.data?.length) return;
    return ledgerItems?.data.map((result) => {
      if (prevMeta && prevMeta !== result.meta.transactionId) {
        isGray = !isGray;
      }

      prevMeta = result.meta.transactionId;

      return (
        <Grid
          item
          container
          width="100%"
          flexDirection="row"
          key={uuid4()}
          sx={{ backgroundColor: isGray ? 'lightgray' : 'unset' }}
        >
          <Grid item xs={1}>
            <Typography fontSize={12} fontWeight={400}>
              {moment(result.datetime).format('DD-MM-YYYY HH:mm:ss')}
            </Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography fontSize={12} fontWeight={400} sx={{ paddingLeft: '4px' }}>
              {result.meta.transactionId}
            </Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography fontSize={12} fontWeight={400}>
              {result.accounts}
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ borderRight: '1px solid black' }}>
            <Typography fontSize={12} fontWeight={400}>
              {Math.abs(round2(result.debit))}
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ borderLeft: '1px solid black' }}>
            <Typography fontSize={12} fontWeight={400}>
              {Math.abs(round2(result.credit))}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography fontSize={12} fontWeight={400}>
              {result.memo}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <GridCellExpand value={JSON.stringify(result.meta, null, 2) || ''} width={600} />
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Grid item xs={12} component={Paper} sx={{ p: 2, mt: 2 }}>
      {!hideFilter && (
        <Grid container xs={12}>
          <TextField
            name="filter"
            placeholder="Filter"
            label="Filter"
            variant="standard"
            onChange={onFilterChanged}
            type="string"
            value={value || filter}
          />
        </Grid>
      )}

      {items?.data && !items.data.length && (
        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="h6">No Available Data</Typography>
        </Grid>
      )}
      {items?.data && items.data.length ? (
        <Grid container flexDirection="column">
          <Grid
            item
            container
            width="100%"
            flexDirection="row"
            sx={{ borderBottom: '2px solid black', marginTop: '12px' }}
          >
            <Grid item xs={1}>
              Datetime
            </Grid>
            <Grid item xs={2.5}>
              Transaction ID
            </Grid>
            <Grid item xs={2.5}>
              Accounts
            </Grid>
            <Grid item xs={1} sx={{ borderRight: '1px solid black' }}>
              Debit
            </Grid>
            <Grid item xs={1} sx={{ borderLeft: '1px solid black' }}>
              Credit
            </Grid>
            <Grid item xs={2}>
              Memo
            </Grid>
            <Grid item xs={1}>
              Payload
            </Grid>
          </Grid>

          {renderLedgerItems()}

          <Grid
            item
            container
            width="100%"
            flexDirection="row"
            sx={{ borderBottom: '2px solid black', borderTop: '2px solid black' }}
          >
            <Grid item xs={1}>
              &nbsp;
            </Grid>
            <Grid item xs={2.5}>
              &nbsp;
            </Grid>
            <Grid item xs={2.5}>
              &nbsp;
            </Grid>
            <Grid item xs={1} sx={{ borderRight: '1px solid black' }}>
              <Typography fontSize={14} fontWeight={400}>
                {Math.abs(round2(ledgerItems?.totalDebit) || 0)}
              </Typography>
            </Grid>
            <Grid item xs={1} sx={{ borderLeft: '1px solid black' }}>
              <Typography fontSize={14} fontWeight={400}>
                {Math.abs(round2(ledgerItems?.totalCredit) || 0)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              &nbsp;
            </Grid>
            <Grid item xs={1}>
              &nbsp;
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {items?.data && items.data.length ? <Typography variant="h6">Total: {ledgerItems?.totalRaws}</Typography> : null}

      {items?.data && items.data.length ? (
        <TablePagination
          component="div"
          count={ledgerItems?.totalRaws}
          // eslint-disable-next-line no-unsafe-optional-chaining
          page={ledgerItems?.page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={perPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </Grid>
  );
}
