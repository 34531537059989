import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import uuid4 from 'uuid4';
import Container from '@mui/material/Container';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { GridFilterItem } from '@mui/x-data-grid-premium';
import { CircularProgress } from '@mui/material';
import { MTabPanel } from '../../components/TabPanel';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useSearchParamsState } from '../../hooks';
import { appConfigSelector } from '../../redux/modules/appConfig/appConfig.selectors';
import { PortfolioOrderTable } from './PortfolioOrderTable';
import { UserPortfolioRequestType } from '../../types/userPortfolioRequest';
import QuickFilter from '../../components/QuickFilter';
import { authSelector } from '../../redux/features/auth/auth.selectors';

const mapTabParamToValue: { [prop: string]: string } = {
  subscription: '1',
  redemption: '2',
  default: '1',
};
const mapTabValueToTabParam: { [prop: string]: string } = {
  1: 'subscription',
  2: 'redemption',
};

const timeToWait = 3500;

export function PortfolioOrderList() {
  const [selectedTab, setSelectedTab] = useSearchParamsState('tab', 'default');

  const { fetchStatus, updateStatus } = useSelector(appConfigSelector);

  const isLoading = fetchStatus === fetchStatuses.pending || updateStatus === fetchStatuses.pending;

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [fundCategories, setFundCategories] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [executionStatuses, setExecutionStatuses] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedFundCategories, setSelectedFundCategories] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selectedExecutionStatuses, setSelectedExecutionStatuses] = useState<string[]>([]);
  const [initiateFilters, setInitiateFilters] = useState<boolean>(true);
  const [productsExternalFilter, setProductsExternalFilter] = useState<GridFilterItem | object>({});
  const [fundCategoriesExternalFilter, setFundCategoriesExternalFilter] = useState<GridFilterItem | object>({});
  const [statusesExternalFilter, setStatusesExternalFilter] = useState<GridFilterItem | object>({});
  const [executionStatusesExternalFilter, setExecutionStatusesExternalFilter] = useState<GridFilterItem | object>({});
  const { user } = useSelector(authSelector);
  let filters = localStorage.getItem(`portfolioOrderListFiltersSelection${mapTabParamToValue[selectedTab]}`);

  const setDataToStorage = (data, currentTab) => {
    if (!user?.id || initiateFilters) return;
    const parsedFilters = filters ? JSON.parse(filters)[user?.id] : {};

    localStorage.setItem(
      `portfolioOrderListFiltersSelection${mapTabParamToValue[currentTab || selectedTab]}`,
      JSON.stringify({
        [user?.id]: { ...parsedFilters, ...data },
      }),
    );
  };

  const onSelectProduct = (filter, currentTab = '', changeTab = false) => {
    setLoading(true);
    switch (filter) {
      case 'Select All':
        // eslint-disable-next-line no-case-declarations
        const allProducts = products.filter((item) => item !== 'Select All' && item !== 'Clear All');
        setSelectedProducts(allProducts);
        if (currentTab) setDataToStorage({ selectedProducts: allProducts }, currentTab);
        setProductsExternalFilter({
          field: 'userPortfolio.subscribedPortfolio.productType.nameEn',
          operator: 'isAnyOf',
          id: uuid4(),
          value: allProducts,
        });
        break;

      case 'Clear All':
        setSelectedProducts([]);
        if (currentTab) setDataToStorage({ selectedProducts: [] }, currentTab);

        setProductsExternalFilter({});
        break;

      default: {
        let newSelectedProducts = selectedProducts.includes(filter)
          ? selectedProducts.filter((item) => item !== filter)
          : [...selectedProducts, filter];
        if (currentTab) setDataToStorage({ selectedProducts: newSelectedProducts }, currentTab);
        else if (selectedProducts.length) newSelectedProducts = [filter];
        else newSelectedProducts = [...selectedProducts, filter];

        setSelectedProducts(newSelectedProducts);
        setTimeout(
          () => {
            setProductsExternalFilter({
              field: 'userPortfolioRequest.userPortfolio.subscribedPortfolio.productType.nameEn',
              operator: 'isAnyOf',
              id: uuid4(),
              value: newSelectedProducts,
            });
          },
          changeTab ? timeToWait : 0,
        );
      }
    }
  };

  const onSelectFundCategory = (filter, currentTab = '', changeTab = false) => {
    setLoading(true);
    switch (filter) {
      case 'Select All': {
        const allFundCategories = fundCategories.filter((item) => item !== 'Select All' && item !== 'Clear All');
        setSelectedFundCategories(allFundCategories);
        if (currentTab) setDataToStorage({ selectedFundCategories: allFundCategories }, currentTab);
        setFundCategoriesExternalFilter({
          field: 'fundCategory.name',
          operator: 'isAnyOf',
          id: uuid4(),
          value: allFundCategories,
        });
        break;
      }

      case 'Clear All':
        setSelectedFundCategories([]);
        if (currentTab) setDataToStorage({ selectedFundCategories: [] }, currentTab);
        setFundCategoriesExternalFilter({});
        break;

      default: {
        let newSelectedFundCategories = selectedFundCategories.includes(filter)
          ? selectedFundCategories.filter((item) => item !== filter)
          : [...selectedFundCategories, filter];
        if (currentTab) setDataToStorage({ selectedFundCategories: newSelectedFundCategories }, currentTab);
        else if (selectedFundCategories.length) newSelectedFundCategories = [filter];
        else newSelectedFundCategories = [...selectedFundCategories, filter];

        setSelectedFundCategories(newSelectedFundCategories);
        setTimeout(
          () => {
            setFundCategoriesExternalFilter({
              field: 'userPortfolioRequest.fundCategory.name',
              operator: 'isAnyOf',
              id: uuid4(),
              value: newSelectedFundCategories,
            });
          },
          changeTab ? timeToWait : 0,
        );
      }
    }
  };

  const onSelectStatus = (filter, currentTab = '', changeTab = false) => {
    setLoading(true);
    switch (filter) {
      case 'Select All':
        // eslint-disable-next-line no-case-declarations
        const allStatuses = statuses.filter((item) => item !== 'Select All' && item !== 'Clear All');
        setSelectedStatuses(allStatuses);
        if (currentTab) setDataToStorage({ selectedStatuses: allStatuses }, currentTab);
        setStatusesExternalFilter({
          field: 'status.name',
          operator: 'isAnyOf',
          id: uuid4(),
          value: allStatuses,
        });
        break;

      case 'Clear All':
        setSelectedStatuses([]);
        if (currentTab) setDataToStorage({ selectedStatuses: [] }, currentTab);

        setStatusesExternalFilter({});
        break;

      default: {
        let newSelectedStatuses = selectedStatuses.includes(filter)
          ? selectedStatuses.filter((item) => item !== filter)
          : [...selectedStatuses, filter];
        if (currentTab) setDataToStorage({ selectedStatuses: newSelectedStatuses }, currentTab);
        else if (selectedStatuses.length) newSelectedStatuses = [filter];
        else newSelectedStatuses = [...selectedStatuses, filter];

        setSelectedStatuses(newSelectedStatuses);
        setTimeout(
          () => {
            setStatusesExternalFilter({
              field: 'status.name',
              operator: 'isAnyOf',
              id: uuid4(),
              value: newSelectedStatuses,
            });
          },
          changeTab ? timeToWait : 0,
        );
      }
    }
  };

  const onSelectExecutionStatus = (filter, currentTab = '', changeTab = false) => {
    setLoading(true);
    switch (filter) {
      case 'Select All':
        // eslint-disable-next-line no-case-declarations
        const allExecutionStatuses = executionStatuses.filter((item) => item !== 'Select All' && item !== 'Clear All');
        setSelectedExecutionStatuses(allExecutionStatuses);
        if (currentTab) setDataToStorage({ selectedExecutionStatuses: allExecutionStatuses }, currentTab);
        setExecutionStatusesExternalFilter({
          field: 'portfolioOrderExecutions[0].status.name',
          operator: 'isAnyOf',
          id: uuid4(),
          value: allExecutionStatuses,
        });
        break;

      case 'Clear All':
        setSelectedExecutionStatuses([]);
        if (currentTab) setDataToStorage({ selectedExecutionStatuses: [] }, currentTab);

        setExecutionStatusesExternalFilter({});
        break;

      default: {
        let newSelectedExecutionStatuses = selectedExecutionStatuses.includes(filter)
          ? selectedExecutionStatuses.filter((item) => item !== filter)
          : [...selectedExecutionStatuses, filter];
        if (currentTab) setDataToStorage({ selectedExecutionStatuses: newSelectedExecutionStatuses }, currentTab);
        else if (selectedExecutionStatuses.length) newSelectedExecutionStatuses = [filter];
        else newSelectedExecutionStatuses = [...selectedExecutionStatuses, filter];

        setSelectedExecutionStatuses(newSelectedExecutionStatuses);
        setTimeout(
          () => {
            setExecutionStatusesExternalFilter({
              field: 'portfolioOrderExecutions[0].status.name',
              operator: 'isAnyOf',
              id: uuid4(),
              value: newSelectedExecutionStatuses,
            });
          },
          changeTab ? timeToWait : 0,
        );
      }
    }
  };

  const clearAllFilters = () => {
    onSelectProduct('Clear All');
    onSelectFundCategory('Clear All');
    onSelectStatus('Clear All');
    onSelectExecutionStatus('Clear All');
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setLoading(true);
    setSelectedTab(mapTabValueToTabParam[newValue]);
    filters = localStorage.getItem(`portfolioOrderListFiltersSelection${newValue}`);
    if (!user?.id) return;

    if (filters) {
      const parsedFilters = JSON.parse(filters)[user?.id];
      if (parsedFilters?.selectedProducts?.length) {
        setSelectedProducts(parsedFilters?.selectedProducts);
        setTimeout(() => {
          setProductsExternalFilter({
            field: 'userPortfolioRequest.userPortfolio.subscribedPortfolio.productType.nameEn',
            operator: 'isAnyOf',
            id: uuid4(),
            value: parsedFilters?.selectedProducts,
          });
        }, timeToWait);
      } else onSelectProduct('Clear All');
      if (parsedFilters?.selectedFundCategories?.length) {
        setSelectedFundCategories(parsedFilters?.selectedFundCategories);
        setTimeout(() => {
          setFundCategoriesExternalFilter({
            field: 'userPortfolioRequest.fundCategory.name',
            operator: 'isAnyOf',
            id: uuid4(),
            value: parsedFilters?.selectedFundCategories,
          });
        }, timeToWait);
      } else onSelectFundCategory('Clear All');
      if (parsedFilters?.selectedStatuses?.length) {
        setSelectedStatuses(parsedFilters?.selectedStatuses);
        setTimeout(() => {
          setStatusesExternalFilter({
            field: 'status.name',
            operator: 'isAnyOf',
            id: uuid4(),
            value: parsedFilters?.selectedStatuses,
          });
        }, timeToWait);
      } else onSelectStatus('Clear All');
      if (parsedFilters?.selectedExecutionStatuses?.length) {
        setSelectedExecutionStatuses(parsedFilters?.selectedExecutionStatuses);
        setTimeout(() => {
          setExecutionStatusesExternalFilter({
            field: 'portfolioOrderExecutions[0].status.name',
            operator: 'isAnyOf',
            id: uuid4(),
            value: parsedFilters?.selectedExecutionStatuses,
          });
        }, timeToWait);
      } else onSelectExecutionStatus('Clear All');
    } else {
      clearAllFilters();
    }
  };

  useEffect(() => {
    const filtersButton = Array.from(document.querySelectorAll('button[type="button"]')).find(
      (el) => el?.textContent?.includes('Filters'),
    );

    if (filtersButton) {
      // Create a MutationObserver to watch for changes in the DOM
      const observer = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (mutation.type === 'childList' || mutation.type === 'subtree') {
            // Look for the "Remove all" button in the entire document
            const removeAllButton = Array.from(document.querySelectorAll('button[type="button"]')).find(
              (el) => el?.textContent?.includes('Remove all') && el.contains(el.firstChild),
            );

            if (removeAllButton) {
              // Stop observing once the button is found

              // Optional: Add click handler to the remove all button
              removeAllButton.addEventListener('click', () => {
                clearAllFilters();
                const urlObj = new URL(window.location.href);
                const tabValue = urlObj.searchParams.get('tab');

                localStorage.removeItem(`portfolioOrderListFiltersSelection${mapTabParamToValue[tabValue || '']}`);
                // Add your click logic here
              });

              observer.disconnect();
              break;
            }
          }
        }
      });

      // Configure the observer to watch the entire document for changes
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    }
  }, [products]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, timeToWait * 1.5);
    }
  }, [loading]);

  useEffect(() => {
    if (!user?.id || !initiateFilters) return;
    setInitiateFilters(false);
    clearAllFilters();
    if (filters) {
      const parsedFilters = JSON.parse(filters)[user?.id];
      if (parsedFilters?.selectedProducts?.length) {
        setSelectedProducts(parsedFilters?.selectedProducts);
        setTimeout(() => {
          setProductsExternalFilter({
            field: 'userPortfolioRequest.userPortfolio.subscribedPortfolio.productType.nameEn',
            operator: 'isAnyOf',
            id: uuid4(),
            value: parsedFilters?.selectedProducts,
          });
        }, timeToWait);
      } else onSelectProduct('Clear All');
      if (parsedFilters?.selectedFundCategories?.length) {
        setSelectedFundCategories(parsedFilters?.selectedFundCategories);
        setTimeout(() => {
          setFundCategoriesExternalFilter({
            field: 'userPortfolioRequest.fundCategory.name',
            operator: 'isAnyOf',
            id: uuid4(),
            value: parsedFilters?.selectedFundCategories,
          });
        }, timeToWait);
      } else onSelectFundCategory('Clear All');
      if (parsedFilters?.selectedStatuses?.length) {
        setSelectedStatuses(parsedFilters?.selectedStatuses);
        setTimeout(() => {
          setStatusesExternalFilter({
            field: 'status.name',
            operator: 'isAnyOf',
            id: uuid4(),
            value: parsedFilters?.selectedStatuses,
          });
        }, timeToWait);
      } else onSelectStatus('Clear All');
      if (parsedFilters?.selectedExecutionStatuses?.length) {
        setSelectedExecutionStatuses(parsedFilters?.selectedExecutionStatuses);
        setTimeout(() => {
          setExecutionStatusesExternalFilter({
            field: 'portfolioOrderExecutions[0].status.name',
            operator: 'isAnyOf',
            id: uuid4(),
            value: parsedFilters?.selectedExecutionStatuses,
          });
        }, timeToWait);
      } else onSelectExecutionStatus('Clear All');
    }
  }, [user?.id]);

  if (!user?.id) return null;

  try {
    return (
      <Container component="main" maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">
            Portfolio Orders &nbsp;
            {/* <ProtectedContent items={[permissions.UserPortfolioRequest.create]}> */}
            {/*   <Fab color="primary" size="small" aria-label="Create User IndexFund Request" onClick={onAddNew}> */}
            {/*     <AddIcon /> */}
            {/*   </Fab> */}
            {/* </ProtectedContent> */}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="bodyMediumB" mr={2}>
            Products
          </Typography>
          <QuickFilter
            key="Products"
            filters={products}
            onSelectFilter={onSelectProduct}
            selectedFilters={selectedProducts}
            selectedTab={selectedTab}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="bodyMediumB" mr={2}>
            Fund Category
          </Typography>
          <QuickFilter
            key="Fund Category"
            filters={fundCategories}
            onSelectFilter={onSelectFundCategory}
            selectedFilters={selectedFundCategories}
            color="success"
            selectedTab={selectedTab}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="bodyMediumB" mr={2}>
            Status
          </Typography>
          <QuickFilter
            key="Status"
            filters={statuses}
            onSelectFilter={onSelectStatus}
            selectedFilters={selectedStatuses}
            color="warning"
            selectedTab={selectedTab}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="bodyMediumB" mr={2}>
            Execution Status
          </Typography>
          <QuickFilter
            key="Execution Status"
            filters={executionStatuses}
            onSelectFilter={onSelectExecutionStatus}
            selectedFilters={selectedExecutionStatuses}
            color="error"
            selectedTab={selectedTab}
          />
        </Box>
        <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
          <TabContext value={mapTabParamToValue[selectedTab]}>
            <Box>
              <TabList
                value={mapTabParamToValue[selectedTab]}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="user portfolio request list tabs"
              >
                <Tab
                  icon={<TrendingUpIcon />}
                  iconPosition="start"
                  label="Subscription"
                  value="1"
                  sx={{ textTransform: 'capitalize' }}
                  disableRipple
                  disabled={isLoading}
                />
                <Tab
                  icon={<TrendingDownIcon />}
                  iconPosition="start"
                  label="Redemption"
                  value="2"
                  sx={{ textTransform: 'capitalize' }}
                  disableRipple
                  disabled={isLoading}
                />
              </TabList>
            </Box>
            <MTabPanel value="1">
              {(selectedTab === mapTabValueToTabParam[1] || selectedTab === 'default') && (
                <PortfolioOrderTable
                  type={UserPortfolioRequestType.SUBSCRIPTION}
                  setProducts={(ps) => setProducts(ps)}
                  setFundCategories={(fcs) => setFundCategories(fcs)}
                  setStatuses={(fcs) => setStatuses(fcs)}
                  setExecutionStatuses={(fcs) => setExecutionStatuses(fcs)}
                  externalFilter={[
                    { ...productsExternalFilter },
                    { ...fundCategoriesExternalFilter },
                    { ...statusesExternalFilter },
                    { ...executionStatusesExternalFilter },
                  ].filter((filter) => Object.keys(filter).length > 0)}
                />
              )}
            </MTabPanel>
            <MTabPanel value="2">
              {selectedTab === mapTabValueToTabParam[2] && (
                <PortfolioOrderTable
                  type={UserPortfolioRequestType.REDEMPTION}
                  setProducts={(ps) => setProducts(ps)}
                  setFundCategories={(fcs) => setFundCategories(fcs)}
                  setStatuses={(fcs) => setStatuses(fcs)}
                  setExecutionStatuses={(fcs) => setExecutionStatuses(fcs)}
                  externalFilter={[
                    { ...productsExternalFilter },
                    { ...fundCategoriesExternalFilter },
                    { ...statusesExternalFilter },
                    { ...executionStatusesExternalFilter },
                  ].filter((filter) => Object.keys(filter).length > 0)}
                />
              )}
            </MTabPanel>
          </TabContext>
        </Box>

        {loading && (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              background: 'rgba(255,255,255,.75)',
              position: 'fixed',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1002,
              top: 0,
              left: 0,
            }}
          >
            <CircularProgress />
          </div>
        )}
      </Container>
    );
  } catch (e: any) {
    return (
      <Container component="main" maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Portfolio Orders</Typography>
        </Box>
        <Typography>Unexpected error happens: ${e.message}</Typography>
      </Container>
    );
  }
}
